import React from "react"

const PostSidebar = () => {
  return (
    <div className="flex flex-col items-end mobile:hidden">
      <div style={{ textAlign: "center" }}>
        <p className="text-lm">Subscribe for updates</p>
        <div className="social-icons flex justify-center items-center mt-3 gap-2">
          {/* twitter */}
          <a
            target="_blank"
            href="https://twitter.com/LivePerson"
            rel="noreferrer"
          >
            <svg
              width="16"
              height="14"
              viewBox="0 0 16 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.3438 3.75C14.9688 3.28125 15.5312 2.71875 15.9688 2.0625C15.4062 2.3125 14.75 2.5 14.0938 2.5625C14.7812 2.15625 15.2812 1.53125 15.5312 0.75C14.9062 1.125 14.1875 1.40625 13.4688 1.5625C12.8438 0.90625 12 0.53125 11.0625 0.53125C9.25 0.53125 7.78125 2 7.78125 3.8125C7.78125 4.0625 7.8125 4.3125 7.875 4.5625C5.15625 4.40625 2.71875 3.09375 1.09375 1.125C0.8125 1.59375 0.65625 2.15625 0.65625 2.78125C0.65625 3.90625 1.21875 4.90625 2.125 5.5C1.59375 5.46875 1.0625 5.34375 0.625 5.09375V5.125C0.625 6.71875 1.75 8.03125 3.25 8.34375C3 8.40625 2.6875 8.46875 2.40625 8.46875C2.1875 8.46875 2 8.4375 1.78125 8.40625C2.1875 9.71875 3.40625 10.6562 4.84375 10.6875C3.71875 11.5625 2.3125 12.0938 0.78125 12.0938C0.5 12.0938 0.25 12.0625 0 12.0312C1.4375 12.9688 3.15625 13.5 5.03125 13.5C11.0625 13.5 14.3438 8.53125 14.3438 4.1875C14.3438 4.03125 14.3438 3.90625 14.3438 3.75Z"
                fill="var(--link-color)"
              />
            </svg>
          </a>
          {/* facebook */}
          <a
            href="https://www.facebook.com/liveperson"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.75 8C15.75 3.71875 12.2812 0.25 8 0.25C3.71875 0.25 0.25 3.71875 0.25 8C0.25 11.875 3.0625 15.0938 6.78125 15.6562V10.25H4.8125V8H6.78125V6.3125C6.78125 4.375 7.9375 3.28125 9.6875 3.28125C10.5625 3.28125 11.4375 3.4375 11.4375 3.4375V5.34375H10.4688C9.5 5.34375 9.1875 5.9375 9.1875 6.5625V8H11.3438L11 10.25H9.1875V15.6562C12.9062 15.0938 15.75 11.875 15.75 8Z"
                fill="var(--link-color)"
              />
            </svg>
          </a>
          {/* rss */}
          <a href="/rss.xml" target="_blank" rel="noreferrer">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.63951 11.2857C3.63951 10.3393 2.87165 9.57143 1.92522 9.57143C0.978795 9.57143 0.210938 10.3393 0.210938 11.2857C0.210938 12.2321 0.978795 13 1.92522 13C2.87165 13 3.63951 12.2321 3.63951 11.2857ZM8.21094 12.3839C8.05915 10.4821 7.2288 8.6875 5.87165 7.33929C4.52344 5.98214 2.72879 5.15179 0.827009 5C0.809152 5 0.800223 5 0.782366 5C0.639509 5 0.496652 5.05357 0.398438 5.15179C0.273438 5.25893 0.210938 5.41071 0.210938 5.57143V6.77679C0.210938 7.07143 0.434152 7.3125 0.728795 7.33929C3.45201 7.59821 5.61272 9.75893 5.87165 12.4821C5.89844 12.7768 6.13951 13 6.43415 13H7.63951C7.80022 13 7.95201 12.9375 8.05915 12.8125C8.1663 12.6964 8.21987 12.5446 8.21094 12.3839ZM12.7824 12.4018C12.6217 9.27679 11.3092 6.33929 9.08594 4.125C6.87165 1.90178 3.93415 0.589285 0.809152 0.428571C0.800223 0.428571 0.791295 0.428571 0.782366 0.428571C0.639509 0.428571 0.496652 0.482142 0.389509 0.589285C0.273438 0.696428 0.210938 0.839285 0.210938 0.999999V2.27678C0.210938 2.57143 0.44308 2.82143 0.746652 2.83928C5.93415 3.14286 10.0681 7.27679 10.3627 12.4643C10.3806 12.7679 10.6306 13 10.9342 13H12.2109C12.3717 13 12.5145 12.9375 12.6217 12.8214C12.7377 12.7054 12.7913 12.5536 12.7824 12.4018Z"
                fill="var(--link-color)"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  )
}
export default PostSidebar
